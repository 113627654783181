export const environment = {
  production: true,
  PURE_API_URL: 'https://beta.tassvision.ai:29001',
  API_URL: 'https://beta.tassvision.ai:29001/api',
  API_AVATAR_IMG_PATH: 'https://beta.tassvision.ai:29001/api/users/avatar',
  IMAGE_EXTERNALAPI_URL: 'https://cjm.tassvision.uz:30001/nvr/',
  VA_SNAPSHOT_IMAGE_LINK: 'https://va.tassvision.uz:11898/images/',
  MOBILE_APP_MODULES: ['CRM', 'OSA', 'BOARD', 'STATS'],
  INTERCOM_APP_ID: "ghtykkkf"
};
